import { useMsal } from "@azure/msal-react";
import {
  ChevronDownIcon,
  PencilSquareIcon,
  UserCircleIcon,
  TrashIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";

import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

// import Input from "../components/TextInput.tsx";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
// @ts-ignore

import { Container } from "react-bootstrap";

import { useAppDispatch } from "../hooks/app";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  getAllOrgnization,
  getAllOrgnizationForDropdown,
  getOrgDetails,
} from "../redux/actions/orgnization";
import TextInput from "../components/Input";
import CoreKindergardenComponent from "./application_users/core-k";
import CoreAdvantageComponent from "./application_users/core-a";
import LetterLinkUserComponent from "./application_users/letter_link";
import ClassroomCoachComponent from "./application_users/core-c";
import ReadySchollAssessmentComponent from "./application_users/core-r";
import HSSelect from "../components/Select";
import { registerUser, resetCreateUserdata } from "../redux/actions/user";
import { validateEmailId } from "../utils";
import { USER_ROLES } from "../utils/constant";
import CorePreschoolComponent from "./application_users/core-p";
import { application } from "express";
import CurriculumAssessmentComponent from "./application_users/core-cu";
import { DisabledByDefault } from "@mui/icons-material";
import HSDialog from "../components/Dialog";
import { isEmpty } from "lodash";

interface Role {
  role_name: string;
  role_id: number;
  id: number;
  isSubDropDown: boolean;
}

export const ImportUser = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [firstName, setFirstName] = useState<string | undefined>();
  const [nameError, setNameError] = useState<string | undefined>();
  const [middleName, setMiddleName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [emailError, setEmailError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [organization, setOrganization] = useState<any>("");

  const [orgDetails, setOrgDetails] = useState<any>("");
  const userDetailsCorA = useSelector(
    (state: RootState) => state.organization.userDetailsCorA
  );

  const [applications, setApplications] = useState<Array<any>>([]);

  const [open, setOpen] = useState<number>(1);

  const org = useSelector((state: RootState) => state.organization);
  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const userData = useSelector((state: RootState) => state.user);
  const [selectedUserToImport, setSelectedUserToImport] = useState<any>("");
  console.log("selectedUserToImport: ", selectedUserToImport);
  const [selectedMainRole, setSelectedMainRole] = useState<any>("");

  const [letterLinkValid, setLetterLinkValid] = useState<boolean>(false);
  const [RSAValid, setRSAValid] = useState<boolean>(false);
  const [CUValid, setCUValid] = useState<boolean>(false);
  const [corAdvValid, setCorAdvtValid] = useState<boolean>(false);
  const [corKValid, setCorKValid] = useState<boolean>(false);
  const [alertDialog, showAlertDialog] = useState<boolean | null>(null);
  const [classroomCoachValid, setClassroomCoachValid] =
    useState<boolean>(false);
  const [areChipsSelected, setAreChipsSelected] = useState<Array<boolean>>([]);
  const [PQAValid, setPQAValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllOrgnizationForDropdown());
  }, []);

  useEffect(() => {
    if (org && userDetails?.role === USER_ROLES.HIGHSCOPE_ORG_USER) {
      const orgFind = org?.orgList?.filter(
        (orgL: any) => orgL?.id === userDetails?.orgnization?.id
      );
      setOrganization(orgFind[0]);
      setOrgDetails(orgFind[0]);
    }
  }, [userDetails, org]);

  const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

  const initialApplication = {
    id: "",
    appId: -1,
    headerTitle: "",
    logo: "",
    code: "",
    role: "",
    isDataValid: false,
    userDetails: {
      applicationId: undefined,
      applicationRoleId: undefined,
      orgId: undefined,
      orgName: "",
      mainRole: "",
    },
  };

  const addApplication = () => {
    const temAppList = [...applications];
    setApplications(temAppList);
  };

  const onSelectOption = (value: any, type: "DELETE" | "ADD") => {
    const temAppList = [...applications];
    if (type === "ADD" && isSelected(value) === -1) {
      const menuData = menuList.find(
        (e) => e.applicationCode === value.applicationCode
      );
      initialApplication.appId = menuData?.id || -1;
      initialApplication.id = value.applicationCode;
      initialApplication.headerTitle = menuData?.name || "";
      initialApplication.logo = menuData?.logo || "";
      initialApplication.code = value.applicationCode;
      initialApplication.role = value.applicationRole;
      // const temAppList = [...applications];
      temAppList.push(initialApplication);
      setApplications(temAppList);
      handleOpen(applications.length);
      handleChipsSelected("ADD");
    } else if (type === "DELETE") {
      handleChipsSelected(
        "DELETE",
        temAppList.findIndex((ele: any) => ele.id === value.id)
      );
      temAppList.splice(isSelected(value.id), 1);
      setApplications(temAppList);
    }
  };

  const handleChipsSelected = (choice: "ADD" | "DELETE", index?: any) => {
    if (choice === "ADD") {
      let data = [...areChipsSelected];
      data.push(false);
      setAreChipsSelected([...data]);
    } else if (choice === "DELETE") {
      let data = [...areChipsSelected];
      data.splice(index, 1);
      setAreChipsSelected([...data]);
    }
  };

  const isSelected = (menuId: any) => {
    return applications.findIndex(
      (element) => element.code === menuId.applicationCode
    );
  };
  const menuList = [
    {
      id: 1,
      name: "COR Advantage",
      logo: "/images/coreA.webp",
      applicationCode: "COR-A",
    },
    {
      id: 2,
      name: "COR FOR Kindergarten",
      logo: "/images/cork.webp",
      applicationCode: "COR-K",
    },
    {
      id: 3,
      name: "Classroom Coach",
      logo: "/images/classroomCoach.webp",
      applicationCode: "COR-C",
    },
    {
      id: 4,
      name: "Ready School Assessment",
      logo: "/images/logoRSA.webp",
      applicationCode: "COR-R",
    },
    {
      id: 5,
      name: "Letter Links",
      logo: "/images/letterLink.webp",
      applicationCode: "COR-L",
    },
    {
      id: 7,
      name: "Pre School Assessment",
      logo: "/images/logoPQA.webp",
      applicationCode: "COR-P",
    },
    {
      id: 8,
      name: "Curriculum",
      logo: "/images/pqa_logo.png",
      applicationCode: "COR-CU",
    },

    // {
    //   id: 7,
    //   name: "PQA",
    //   logo: "/images/coreA.webp",
    //   applicationCode: "COR-P",
    // },
  ];

  const toggleHeader = (value: number) => {
    if (open === value) {
      handleOpen(-1);
    } else {
      handleOpen(value);
    }
  };

  const onChangeUserDetails = (value: any, type: any) => {
    if (type === "firstName") {
      setFirstName(value);
    } else if (type === "lastName") {
      setLastName(value);
    } else if (type === "middleName") {
      setMiddleName(value);
    } else if (type === "email") {
      setEmail(value);
    } else if (type === "userName") {
      setUserName(value);
    } else if (type === "organization") {
      setOrganization(value);
    } else if (type === "phone") {
      setPhoneNumber(value);
    }
  };

  const handleDataFromLetterLink = (data: any) => {
    if (data.mainRole?.role_id === 100000) {
      setLetterLinkValid(true);
    } else if (data.mainRole?.role_id === 100001) {
      setLetterLinkValid(false);

      if (
        data.subRole?.role_id === 100004 &&
        (data.classRoom === undefined || data.classRoom === "")
      ) {
        setLetterLinkValid(false);
      } else if (data.subRole?.role_id === 100001 && !data.userType) {
        setLetterLinkValid(false);
      } else if (data.subRole?.role_id === 100004 && data.classRoom) {
        setLetterLinkValid(true);
      } else if (data.subRole?.role_id === 100001 && data.userType) {
        setLetterLinkValid(true);
      }
    }

    const userDetail = applications.find((app) => app?.id === "COR-L");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId =
      data?.mainRole?.role_id === 100000
        ? data?.mainRole?.id
        : data?.subRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole =
      data?.mainRole?.role_id === 100000
        ? data?.mainRole?.role_name
        : data?.subRole?.role_name;

    //classroom Name
    userDetail.userDetails.other1 = data?.classRoom
      ? data?.classRoom
      : undefined;

    //user Type
    userDetail.userDetails.other2 = data?.userType
      ? data?.userType?.role_id
      : null;

    // Role_Entity;
    userDetail.userDetails.other3 =
      data?.mainRole?.role_id === 100000
        ? data?.mainRole?.role_id.toString()
        : data?.subRole?.role_id.toString();
  };

  const handleDataFromCorAdv = (data: any) => {
    if (data.selectedUserImport) {
      setSelectedUserToImport(data.selectedUserImport);
    }
    if (data.mainRole) {
      setSelectedMainRole(data.mainRole);
    }
    if (data.mainRole?.role_id === 100000) {
      setCorAdvtValid(true);
    } else if (data?.mainRole?.role_id === 100001) {
      setCorAdvtValid(true);
    } else if (data?.mainRole?.role_id === 100003) {
      setCorAdvtValid(false);
      if (data?.selectedSites?.length > 0) {
        setCorAdvtValid(true);
      }
    } else if (data.mainRole?.role_id === 100004) {
      setCorAdvtValid(false);
      if (data?.selectedSites && data?.selectedClassrooms?.length > 0) {
        setCorAdvtValid(true);
      }
    }
    const userDetail = applications.find((app) => app?.id === "COR-A");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole = data?.mainRole?.role_name;

    if (data?.mainRole?.role_id === 100003) {
      userDetail.userDetails.siteDetails = undefined;
      if (data?.selectedSites?.length > 0) {
        const siteDetails = data?.selectedSites?.map((site: any) => ({
          siteName: site.Center_Name,
          siteId: site._id,
        }));

        userDetail.userDetails.siteDetails = siteDetails;
      }
    } else if (data?.mainRole?.role_id === 100004) {
      userDetail.userDetails.siteDetails = undefined;
      userDetail.userDetails.classDetails = undefined;

      if (data?.selectedSites) {
        const siteDetails = {
          siteName: data?.selectedSites?.Center_Name,
          siteId: data?.selectedSites?._id,
        };

        userDetail.userDetails.siteDetails = [siteDetails];
      }

      if (data?.selectedClassrooms?.length > 0) {
        const classDetails = data?.selectedClassrooms?.map((clas: any) => ({
          className: clas.Class_Name,
          classId: clas._id,
        }));

        userDetail.userDetails.classDetails = classDetails;
      }
    }
  };

  const handleDataFromCorK = (data: any) => {
    if (data.selectedUserImport) {
      setSelectedUserToImport(data.selectedUserImport);
    }

    if (data.mainRole?.role_id === 100000) {
      setCorKValid(true);
    } else if (data.mainRole?.role_id === 100002) {
      setCorKValid(true);
    } else if (data?.mainRole?.role_id === 100001) {
      setCorKValid(false);
      if (data?.selectedSites?.length > 0) {
        setCorKValid(true);
      }
    } else if (data?.mainRole?.role_id === 100004) {
      setCorKValid(false);
      if (
        data?.selectedSite &&
        data?.selectedClassrooms?.length > 0 &&
        data?.subRole
      ) {
        setCorKValid(true);
      }
    }

    const userDetail = applications.find((app) => app?.id === "COR-K");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole = data?.mainRole?.role_name;

    if (data?.mainRole?.role_id === 100001) {
      userDetail.userDetails.siteDetails = undefined;
      if (data?.selectedSites?.length > 0) {
        const siteDetails = data?.selectedSites?.map((site: any) => ({
          siteName: site.Center_Name,
          siteId: site._id,
        }));

        userDetail.userDetails.siteDetails = siteDetails;
      }
    } else if (data?.mainRole?.role_id === 100004) {
      userDetail.userDetails.siteDetails = undefined;
      userDetail.userDetails.classDetails = undefined;

      if (data?.selectedSite) {
        const siteDetails = {
          siteName: data?.selectedSite.Center_Name,
          siteId: data?.selectedSite._id,
        };

        userDetail.userDetails.siteDetails = [siteDetails];
      }

      if (data?.selectedClassrooms?.length > 0) {
        const classDetails = data?.selectedClassrooms?.map((clas: any) => ({
          className: clas.Class_Name,
          classId: clas._id,
        }));
        userDetail.userDetails.classDetails = classDetails;
      }
      //Teacher Profile
      userDetail.userDetails.other1 = data?.subRole?.role_name;
    }
  };

  const handleDataFromClassroomCoach = (data: any) => {
    if (data?.mainRole?.role_id === 100002) {
      setClassroomCoachValid(false);
      if (data?.selectedLevel?.value === "level1") {
        setClassroomCoachValid(true);
      } else if (data?.selectedLevel?.value === "level2") {
        setClassroomCoachValid(false);
        if (data.selectedServiceCenter) {
          setClassroomCoachValid(true);
        } else {
          setClassroomCoachValid(false);
        }
      } else if (data?.selectedLevel?.value === "level3") {
        setClassroomCoachValid(false);
        if (data?.selectedServiceCenter && data?.selectedSubReceipient) {
          setClassroomCoachValid(true);
        } else {
          setClassroomCoachValid(false);
        }
      }
    } else if (data?.mainRole?.role_id === 100004) {
      setClassroomCoachValid(false);
      if (data?.subRole?.role_id === 100001) {
        if (data?.selectedSite?.length > 0 && data?.selectedHierarchy) {
          setClassroomCoachValid(true);
        } else setClassroomCoachValid(false);
      }
      if (data?.subRole?.role_id === 100004) {
        if (
          data?.selectedSite &&
          data?.selectedClassrooms &&
          data?.selectedTeacherProfile
        ) {
          setClassroomCoachValid(true);
        } else setClassroomCoachValid(false);
      }
    }

    const userDetail = applications.find((app) => app?.id === "COR-C");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId =
      data?.mainRole?.role_id === 100002
        ? data?.mainRole?.id
        : data?.subRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole =
      data?.mainRole?.role_id === 100002
        ? data?.mainRole?.role_name
        : data?.subRole?.role_name;

    if (data?.subRole?.role_id === 100001) {
      // hierarchy
      //other 2 -> level
      //other 3 -> hierarchy Id -> id of entity/service center/ sub receipient

      userDetail.userDetails.siteDetails = undefined;
      if (data?.selectedSite?.length > 0) {
        const siteDetails = data?.selectedSite?.map((site: any) => ({
          siteName: site.Center_Name,
          siteId: site._id,
        }));

        userDetail.userDetails.siteDetails = siteDetails;
        userDetail.userDetails.other2 = data?.selectedHierarchy?.level;
        userDetail.userDetails.other3 = data?.selectedHierarchy?.value;
      }
    }

    if (data?.mainRole?.role_id === 100002) {
      // hierarchy
      //other 2 -> level
      //other 3 -> Service Center Id
      //other 4 -> Sub Receipient Id
      if (data?.selectedLevel?.value === "level1") {
        userDetail.userDetails.other2 = data?.selectedLevel?.value;
        userDetail.userDetails.other3 = null;
        userDetail.userDetails.other4 = null;
      } else if (data?.selectedLevel?.value === "level2") {
        userDetail.userDetails.other2 = data?.selectedLevel?.value;
        userDetail.userDetails.other3 = data?.selectedServiceCenter?._id;
        userDetail.userDetails.other4 = null;
      } else if (data?.selectedLevel?.value === "level3") {
        userDetail.userDetails.other2 = data?.selectedLevel?.value;
        userDetail.userDetails.other3 = data?.selectedServiceCenter?._id;
        userDetail.userDetails.other4 = data?.selectedSubReceipient?._id;
      }
    } else if (data?.subRole?.role_id === 100004) {
      userDetail.userDetails.siteDetails = undefined;
      userDetail.userDetails.classDetails = undefined;

      if (data?.selectedSite) {
        const siteDetails = {
          siteName: data?.selectedSite.Center_Name,
          siteId: data?.selectedSite._id,
        };

        userDetail.userDetails.siteDetails = [siteDetails];
      }

      if (data?.selectedClassrooms) {
        const classDetails = [
          {
            className: data?.selectedClassrooms.Class_Name,
            classId: data?.selectedClassrooms._id,
          },
        ];

        userDetail.userDetails.classDetails = classDetails;
      }
      //Teacher Profile
      userDetail.userDetails.other1 = data?.selectedTeacherProfile?.label;
    }
  };
  const handleDataFromRSA = (data: any) => {
    if (data.mainRole?.role_id === 100000) {
      setRSAValid(true);
    } else if (data?.mainRole?.role_id === 200001) {
      setRSAValid(true);
    } else if (data?.mainRole?.role_id === 200002) {
      setRSAValid(false);
      if (data?.selectedSite) {
        setRSAValid(true);
      }
    } else if (data?.mainRole?.role_id === 200003) {
      setRSAValid(false);
      if (data?.selectedSite) {
        setRSAValid(true);
      }
    } else if (data?.mainRole?.role_id === 200004) {
      setRSAValid(false);
      if (data?.selectedSite) {
        setRSAValid(true);
      }
    } else if (data?.mainRole?.role_id === 200005) {
      setRSAValid(false);
      if (data?.selectedSite) {
        setRSAValid(true);
      }
    }
    const userDetail = applications.find((app) => app?.id === "COR-R");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole = data?.mainRole?.role_name;

    if (
      data?.mainRole?.role_id !== 100000 ||
      data?.mainRole?.role_id !== 200001
    ) {
      userDetail.userDetails.siteDetails = undefined;
      if (data?.selectedSite) {
        const siteDetails = [
          {
            siteName: data?.selectedSite?.Site_Name,
            siteId: data?.selectedSite?._id,
          },
        ];
        userDetail.userDetails.siteDetails = siteDetails;
      }
    }
  };

  const handleDataFromCU = (data: any) => {
    if (data.mainRole?.role_id === 100000) {
      setCUValid(true);
    } else if (data?.mainRole?.role_id === 100001) {
      setCUValid(true);
    } else if (data?.mainRole?.role_id === 100002) {
      setCUValid(false);
      if (data?.selectedSite) {
        setCUValid(true);
      }
    }
    if (data?.mainRole?.role_id === 100004) {
      setCUValid(false);
      if (data?.selectedSite) {
        setCUValid(true);
      }
    }
    const userDetail = applications.find((app) => app?.id === "COR-CU");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole = data?.mainRole?.role_name;

    if (
      data?.mainRole?.role_id !== 100000 ||
      data?.mainRole?.role_id !== 100001
    ) {
      userDetail.userDetails.siteDetails = undefined;
      if (data?.selectedSite) {
        const siteDetails = [
          {
            siteName: data?.selectedSite?.Site_Name,
            siteId: data?.selectedSite?._id,
          },
        ];
        userDetail.userDetails.siteDetails = siteDetails;
      }
    }
    if (
      data?.mainRole?.role_id !== 100000 ||
      data?.mainRole?.role_id !== 100001 ||
      data?.mainRole?.role_id !== 100002
    ) {
      userDetail.userDetails.classDetails = undefined;
      if (data?.selectedClassroom) {
        const classDetails = [
          {
            className: data?.selectedClassroom?.Classroom_Name,
            classId: data?.selectedClassroom?._id,
          },
        ];
        userDetail.userDetails.classDetails = classDetails;
      }
    }
  };

  const handleDataFromPQA = (data: any) => {
    if (data.mainRole?.role_id === 100000) {
      setPQAValid(true);
    } else if (data.mainRole?.role_id === 100001) {
      setPQAValid(true);
    } else if (data?.mainRole?.role_id === 100002) {
      setPQAValid(false);
      if (data?.selectedSites) {
        setPQAValid(true);
      }
    } else if (data?.mainRole?.role_id === 100003) {
      setPQAValid(false);
      if (data?.selectedSites) {
        setPQAValid(true);
      }
    } else if (data?.mainRole?.role_id === 100004) {
      setPQAValid(false);
      if (data?.selectedClassrooms?.length > 0) {
        setPQAValid(true);
      }
    }
    const userDetail = applications.find((app) => app?.id === "COR-P");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole = data?.mainRole?.role_name;

    if (data?.mainRole?.role_id === 100001) {
      userDetail.userDetails.siteDetails = undefined;
      userDetail.userDetails.classDetails = undefined;
      if (data?.selectedSites?.length > 0) {
        const siteDetails = data?.selectedSites?.map((site: any) => ({
          siteName: site.Center_Name,
          siteId: site._id,
        }));
        userDetail.userDetails.siteDetails = siteDetails;
      }
    } else if (data?.mainRole?.role_id === 100004) {
      userDetail.userDetails.siteDetails = undefined;
      userDetail.userDetails.classDetails = undefined;
      if (data?.selectedSites) {
        const siteDetails = {
          siteName: data?.selectedSites.Center_Name,
          siteId: data?.selectedSites._id,
        };
        userDetail.userDetails.siteDetails = [siteDetails];
      }
      if (data?.mainRole?.role_id === 100004) {
        const classDetails = data?.selectedClassrooms?.map((clas: any) => ({
          className: clas.Class_Name,
          classId: clas._id,
        }));
        userDetail.userDetails.classDetails = classDetails;
      }
    } else if (data?.mainRole?.role_id === 100002) {
      const siteDetails = {
        siteName: data?.selectedSites?.Center_Name,
        siteId: data?.selectedSites?._id,
      };
      userDetail.userDetails.siteDetails = [siteDetails];
    } else if (data?.mainRole?.role_id === 100003) {
      if (data?.selectedSites && Array.isArray(data.selectedSites)) {
        const siteDetails = data.selectedSites.map((selectedSite: any) => ({
          siteName: selectedSite.Center_Name,
          siteId: selectedSite._id,
        }));
        userDetail.userDetails.siteDetails = siteDetails;
      }
    }
  };

  //  ******************  Heirarchy Code   ******************

  const createUser = () => {
    const temAppList: any = [];

    if (applications.length > 0) {
      applications?.map((app) => {
        temAppList.push(app.userDetails);
      });
    }

    selectedUserToImport.forEach((user: any) => {
      const body = {
        lastName: user.Last_Name,
        firstName: user.First_Name,
        role: "HighscopeOrgAdmin",
        email: user.Email_Id,
        username: user.Username,
        orgnizationId: organization.id,
        userDetails: temAppList.map((item: any) => {
          if (item.mainRole === "Director" || item.mainRole === "Principle") {
            // Assuming user object contains center details
            return {
              ...item,
              siteDetails: user.Centers.map((center: any) => ({
                siteName: center.label,
                siteId: center.value,
              })),
            };
          } else if (
            item.mainRole == "Teacher" &&
            !item.siteDetails &&
            !item.classDetails &&
            user.Center_Data
          ) {
            return {
              ...item,

              siteDetails: [
                {
                  siteName: user.Center_Data.label,
                  siteId: user.Center_Data.value,
                },
              ],
              classDetails: user.Classrooms.map((data: any) => ({
                className: data.label,
                classId: data.value,
              })),
            };
          } else if (
            item.mainRole == "Teacher" &&
            !item.siteDetails &&
            !item.classDetails
          ) {
            return {
              ...item,
              siteDetails: user.Centers.map((data: any) => ({
                siteName: data.label,
                siteId: data.value,
              })),
              classDetails: user.Classrooms.map((data: any) => ({
                className: data.label,
                classId: data.value,
              })),
            };
          } else {
            return item;
          }
        }),
        middleName: user.Middle_Name,
        phone: user.Phone_Number,
      };

      dispatch(registerUser(body));
    });

    // resetData();
  };

  useEffect(() => {
    if (userData?.createdUserStatus) {
      resetData();
      dispatch(resetCreateUserdata());
    }
  }, [userData?.createdUserStatus]);

  const resetData = () => {
    setFirstName("");
    setFirstNameError("");
    setLastName("");
    setLastNameError("");
    setMiddleName("");
    setMiddleNameError("");
    setUserName("");
    setUserNameError("");
    setEmail("");
    setEmailError("");
    setPhoneNumber("");
    setPhoneError("");
    setOrgDetails(null);
    setOrganization(null);
    setApplications([]);
    setAreChipsSelected([]);
  };

  const [isDataValid, setIsDataValid] = useState(false);

  const getAccourdianBoady = (type: any, index: number) => {
    switch (type.id) {
      case "COR-K":
        return (
          <CoreKindergardenComponent
            roles={type.role}
            org={organization}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-K"
                  )?.application_role.role_id
            }
            onDataChange={handleDataFromCorK}
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      case "COR-A":
        return (
          <CoreAdvantageComponent
            roles={type.role}
            org={organization}
            onDataChange={handleDataFromCorAdv}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-A"
                  )?.application_role.role_id
            }
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      case "COR-L":
        return (
          <LetterLinkUserComponent
            roles={type.role}
            onDataChange={handleDataFromLetterLink}
            org={organization}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-L"
                  )?.application_role.role_id
            }
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      case "COR-C":
        return (
          <ClassroomCoachComponent
            roles={type.role}
            org={organization}
            onDataChange={handleDataFromClassroomCoach}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-C"
                  )?.application_role.role_id
            }
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      case "COR-R":
        return (
          <ReadySchollAssessmentComponent
            roles={type.role}
            org={organization}
            // org={org?.orgnizationData?.results["COR-R"]?.entity_data}
            onDataChange={handleDataFromRSA}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-R"
                  )?.application_role.role_id
            }
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      case "COR-CU":
        return (
          <CurriculumAssessmentComponent
            roles={type.role}
            org={organization}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-CU"
                  )?.application_role.role_id
            }
            onDataChange={handleDataFromCU}
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );

      case "COR-P":
        return (
          <CorePreschoolComponent
            roles={type.role}
            org={organization}
            onDataChange={handleDataFromPQA}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-P"
                  )?.application_role.role_id
            }
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      default:
        return null;
    }
  };

  const getBadge = (type: string) => {
    switch (type) {
      case "COR-K":
        return corKValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-A":
        return corAdvValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-L":
        return letterLinkValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-R":
        return RSAValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-CU":
        return CUValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-C":
        return classroomCoachValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-P":
        return PQAValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-AL":
        return null;
      default:
        return null;
    }
  };

  return (
    <>
      <>
        <Container className="h-full bg-white p-4 min-w-[82vw] font-['poppins'] overflow-auto ">
          <div className="h-full res_height ">
            <div className="flex flex-row justify-center  bg-[#264390] max-w-[300px]  mx-auto text-white font-medium p-2 min-h-fit max-h-full rounded-sm ">
              <IdentificationIcon className="w-8 h-8 mr-2 " />
              <span className="text-2xl font-medium ">Import User</span>
            </div>
            <div className="Card my-3 rounded-md shadow-sm bg-[#ececec] ">
              <div className="p-4 xl:h-[57vh] 2xl:h-[65vh] 1xl:h-[58vh] lg:h-[55vh] md:h-[55vh] overflow-auto">
                <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 px-1 py-3 gap-y-1 gap-x-5">
                  <HSSelect
                    label={"Select Organization"}
                    placeholder="Select Organization"
                    required={true}
                    options={org?.orgList}
                    // disabled={
                    //   userDetails?.role === USER_ROLES.HIGHSCOPE_ORG_USER
                    //     ? true
                    //     : false
                    // }
                    // disabled={!orgDetails ? true : false}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                    value={organization}
                    onChange={(obj: any) => {
                      setApplications([]);
                      onChangeUserDetails(obj, "organization");
                      setOrgDetails(obj);
                    }}
                  />
                  {applications.length < 8 && (
                    <div className="flex self-end text-xl  px-1 rounded-none mb-3 w-full justify-end  ">
                      <Menu>
                        <MenuHandler>
                          <Button
                            className=" w-[280px] flex flex-row items-center  bg-[#264390]  p-2"
                            size="sm"
                            disabled={!orgDetails ? true : false}
                            // color="blue"
                            onClick={() => addApplication()}
                          >
                            <ChevronDownIcon className="w-7 h-7 mr-2 " />
                            <span className="text-[15px] font-normal">
                              {" "}
                              Select Application Access{" "}
                            </span>
                          </Button>
                        </MenuHandler>

                        <MenuList
                          className={`w-[280px] p-0  bg-[#264390] text-white boredr border-white rounded-none -mt-2 `}
                        >
                          {orgDetails &&
                            orgDetails?.applications
                              ?.filter((ele: any) => {
                                if (!userDetails?.userDetails.length) {
                                  return true;
                                } else {
                                  return userDetails.userDetails
                                    .map(
                                      (option: any) =>
                                        option.application.applicationCode
                                    )
                                    .includes(ele.applicationCode);
                                }
                              })
                              .map((menu: any, index: number) => (
                                <MenuItem
                                  key={index}
                                  className={`border-b border-white rounded-none ${
                                    isSelected(menu) === -1
                                      ? ""
                                      : "bg-gray-200 text-gray-600 rounded-none"
                                  }`}
                                  onClick={() => {
                                    onSelectOption(menu, "ADD");
                                  }}
                                >
                                  {menu.applicationName}
                                </MenuItem>
                              ))}
                        </MenuList>
                      </Menu>
                    </div>
                  )}
                </div>

                {applications?.map((obj: any, index: number) => (
                  <Accordion
                    open={open === index}
                    className="mb-2 rounded-lg border border-blue-gray-100 text-center shadow-xl "
                  >
                    <AccordionHeader
                      onClick={() => toggleHeader(index)}
                      className={`bg-[#264390] text-white w-[full] ${
                        open === index
                          ? "rounded-lg rounded-b-none"
                          : "rounded-lg "
                      }   h-[40px] flex flex-row justify-between`}
                    >
                      <div className=" w-full flex flex-row justify-between items-center">
                        <p className="mt-3 px-4 flex flex-row">
                          {obj.headerTitle}
                          {getBadge(obj.code)}
                        </p>
                        <div className=" flex flex-row">
                          <div className="flex text-white">
                            <PencilSquareIcon className="w-8 h-8 mr-3 bg-[#264390] p-1" />
                            <TrashIcon
                              className="w-8 h-8 bg-[#264390] p-1"
                              onClick={() => onSelectOption(obj, "DELETE")}
                            />
                          </div>
                          <ChevronDownIcon className="w-8 h-8 ml-2 items-end" />
                        </div>
                      </div>
                    </AccordionHeader>
                    <AccordionBody className="pt-0 text-base font-normal bg-white relative">
                      {getAccourdianBoady(obj, index)}
                    </AccordionBody>
                  </Accordion>
                ))}
              </div>
              <div
                className={`flex justify-end px-5 border-t-2 border-gray-400 py-2 h-[60px]`}
              >
                <Button
                  className="flex flex-row items-center justify-center bg-[#264390] hover:bg-gray-500   py-1 px-3 2xl:px-4 2xl:py-3 rounded-md"
                  disabled={selectedUserToImport.length > 0 ? false : true}
                  // disabled={areApplicationRolesSelected()}
                  onClick={createUser}
                >
                  <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                    Import Users
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </>
      <HSDialog
        header={"Error"}
        message={errorMessage}
        // message="Please select at least one application"
        handleOpen={(message: any) => showAlertDialog(message)}
        open={alertDialog}
        size={"xs"}
      />
    </>
  );
};
