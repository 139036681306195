import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { b2cPolicies } from "./authConfig2";
import { compareIssuingPolicy } from "./utils/claimUtils";
import { Home } from "./pages/Home";
import { PageLayout } from "./components/PageLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import { CreateUser } from "./pages/CreateUser";
import { CreateOrgnization } from "./pages/CreateOrgnization";
import { Authenticate } from "./pages/authenticate";
import { AuthComplete } from "./pages/auth-complete";
import { Dashboard } from "./pages/Dashboard";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { LogoutUser } from "./pages/logout-complete";
import Testing from "./pages/Testing";
import { AdminDashboard } from "./pages/AdminDashboard";
import { MainDashboard } from "./pages/MainDashboard";
import { ProtectedRoute } from "./route/ProtectedRoute";
import "react-data-grid/lib/styles.css";
import AdminUserList from "./pages/AdminUserList";
import { OrgnizationList } from "./pages/AdminOrgnizationList";
import { EditOrgnization } from "./pages/EditOrganizationList";

import { EditUser } from "./pages/EditUser";
import { CreateHSAdmin } from "./pages/CreateHSAdmin";
import UserProfile from "./pages/UserProfile";
import { EditHSAdmin } from "./pages/EditHSAdmin";
import { ImportUser } from "./pages/ImportUser";

// import AdminUserList from "./pages/AdminUserList";
// import AdminUsers from "./pages/AdminUsers";
// import UserList from "./pages/AdminUserList";
// import AdminUserList from "./pages/AdminUserList";

function AppRouter() {
  const { instance } = useMsal();
  const isUserLoggedIn = useIsAuthenticated();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          event.payload.idTokenClaims["tfp"] === b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account: any) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                account.idTokenClaims["tfp"] === b2cPolicies.names.signUpSignIn
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (
          compareIssuingPolicy(
            event.payload.idTokenClaims,
            b2cPolicies.names.forgotPassword
          )
        ) {
          let signUpSignInFlowRequest: any = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && event.error.errorMessage.includes("AADB2C90118")) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);
  return (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route index element={<MainDashboard />} />
        <Route path="/login" element={<Authenticate />} />
        <Route path="/createUser" element={<CreateUser />} />
        <Route path="/createOrg" element={<CreateOrgnization />} />
        <Route path="/auth" element={<AuthComplete />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/logout" element={<LogoutUser />} />
        <Route path="/test" element={<Testing />} />
        <Route path="/admin_dashboard" element={<AdminDashboard />} />
        <Route path="/users" element={<AdminUserList />} />
        <Route path="/orgList" element={<OrgnizationList />} />
        <Route path="/editOrgList" element={<EditOrgnization />} />
        {/* <Route path="/editUser" element={<p>dfdf</p>} /> */}
        <Route path="/editUser" element={<EditUser />} />
        <Route path="/editUser/:id/:app" element={<EditUser />} />
        <Route path="/createHSAdmin" element={<CreateHSAdmin />} />
        <Route path="/importUser" element={<ImportUser />} />
        <Route path="/userProfile" element={<UserProfile />} />
        <Route path="/editHSAdmin/:id/:app" element={<EditHSAdmin />} />

        {/* <Route path="/home" element={<Home />} /> */}
      </Route>
    </Routes>
  );
}

const App = ({ instance }: any) => {
  return (
    <BrowserRouter>
      <MsalProvider instance={instance}>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </MsalProvider>
    </BrowserRouter>
  );
};

export default App;
