import { AuthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Card, Container, Image } from "react-bootstrap";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const AdminDashboard = () => {
  const { instance, inProgress } = useMsal();
  const activeAccount: any = instance.getActiveAccount();

  // const activeAccount = instance.getActiveAccount();
  const data1 = useOutletContext();
  const [idToken, setIdToken] = useState();
  const userDetails = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    if (activeAccount && !activeAccount.idToken && inProgress === "none") {
      getTokenResponse();
    } else if (
      activeAccount &&
      activeAccount.idToken &&
      inProgress === "none"
    ) {
      setIdToken(activeAccount.idToken);
    }
  }, [activeAccount, inProgress]);

  const getTokenResponse = async () => {
    const tokenResponse: any = await instance.acquireTokenSilent({
      account: activeAccount,
      scopes: ["offline_access"],
    });
    setIdToken(tokenResponse.idToken);
  };

  const data = [
    {
      id: 1,
      imageUrl: "/images/coreA.webp",
      title: "COR Advantage",
      discription: "Better results. That’s the COR Advantage.",
      button: "Visit Site",
      code: "COR-A",
      link: process.env.REACT_APP_COR_A_URL + "/login",
    },
    {
      id: 2,
      imageUrl: "/images/cork.webp",
      title: "COR For Kindergarten",
      discription: "Bring Your Classroom to Life.",
      button: "Visit Site",
      code: "COR-K",
      link: process.env.REACT_APP_COR_K_URL,
    },
    {
      id: 3,
      imageUrl: "/images/classroomCoach.webp",
      title: "Classroom Coach",
      discription: "Improving Preschool Classroom Quality",
      button: "Visit Site",
      code: "COR-C",
      link: process.env.REACT_APP_COR_C_URL,
    },
    {
      id: 4,
      imageUrl: "/images/logoRSA.webp",
      title: "Ready School Assessment",
      discription:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      button: "Visit Site",
      code: "COR-R",
      link: process.env.REACT_APP_RSA_URL + "/login",
    },
    {
      id: 5,
      imageUrl: "/images/letterLink.webp",
      title: "Letter Links",
      discription:
        "The Letter Links system enables you to introduce your students to letter names and sounds by building on children's natural attachment to their own names.",
      button: "Visit Site",
      code: "COR-L",
      link: process.env.REACT_APP_LETTER_LINK_URL,
    },
    {
      id: 7,
      imageUrl: "/images/logoPQA.webp",
      title: "Program Quality Assessment",
      discription:
        "Reliable, scientifically validated assessment that reflects research-based, field-tested best practices in early childhood education.",
      button: "Visit Site",
      code: "COR-P",
      link: process.env.REACT_APP_COR_P_URL + "/login",
    },
    {
      id: 8,
      imageUrl: "",
      title: "Curriculum",
      discription:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      button: "Visit Site",
      code: "COR-CU",
      link: process.env.REACT_APP_CURR_URL + "/login",
    },
  ];

  return (
    <>
      <AuthenticatedTemplate>
        {/* {activeAccount ? (
          <Container className="h-full bg-white min-w-[82vw] p-4 overflow-auto  ">
            <div>Admin Dashboard Coming Soon</div>{" "}
          </Container>
        ) : null} */}
        {userDetails?.role === "HighscopeAdmin" ? (
          <Container className="h-full bg-white min-w-[82vw] p-4 overflow-auto">
            <div className="h-full">
              <div className="grid md:grid-cols-1 md:gap-2 sm:grid-cols-1 sm:gap-2 xs:grid-cols-1 xs:gap-2 grid-cols-2 gap-3 min-h-fit max-h-full res_height">
                {data.map((item) => (
                  <Card key={item.id} className="shadow-xl dashboard-card">
                    <div className="dashboard-card bg-gray-300 hover:bg-[#264390] flex gap-3 p-2 rounded-md shadow-sm hover:text-white md:flex-row sm:flex-col xs:flex-col min-h-[200px] max-h-[220px]">
                      <div className=" bg-white  w-[35%] rounded-md p-1  flex items-center justify-center ">
                        <Image
                          className="dashboardbutton"
                          src={item.imageUrl}
                          width={180}
                          height={72}
                          alt={item.title}
                        />
                      </div>
                      <div className="w-[65%] hover:text-white">
                        <span className="font-bold">{item.title}</span>
                        <p className="text-md">{item.discription}</p>
                        <div
                          className="visit-button flex bg-[#264390] rounded-sm w-[120px] p-1 mb-3 align-middle text-white hover:text-[#264390]"
                          onClick={() => {
                            window.open(
                              item.link + "/?token=" + idToken,
                              "_self"
                            );
                          }}
                        >
                          <ArrowTopRightOnSquareIcon className="w-5 h-5 mr-1 visit-button" />
                          <button>{item.button}</button>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
                <div className="h-4 bg-white" />
              </div>
            </div>
          </Container>
        ) : (
          <div>
            <h1>No Applications</h1>
          </div>
        )}
      </AuthenticatedTemplate>
    </>
  );
};
